import { Component } from "react";
import CharacterRemover from "character-remover";
import axios from "axios";
import { URL_Scheduling } from "../Services/schedulingService";
import { format } from "date-fns";
import { FaChevronLeft } from "react-icons/fa";
import swal from "sweetalert";
import { URL_PreScheduling } from "../Services/preSchedulingService";
import { URL_DigitalPayment } from "../Services/digitalPaymentService";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase";
import { RecaptchaVerifier } from "firebase/auth";
import { Spinner } from "../../common/components/spinner/spinner";
import { MdExpandMore } from "react-icons/md";
export default class SchedulingsSearch extends Component {
  constructor(props) {
    super(props);
    const myParam = window.location.pathname.split("/")[1];
    const myParamTel = window.location.pathname.split("/")[3];

    this.state = {
      listSchedulings: {
        results: [],
        currentPage: 0,
        pageCount: 0,
        pageSize: 10,
        rowCount: 0,
        firstRowOnPage: 0,
        lastRowOnPage: 0,
      },
      formFilter: { cellPhoneOption: myParamTel, pageNumber: 1, pageSize: 10 },
      errors: {},
      loading: true,
      loadingModal: false,
      loadUncheck: false,
      idScheduling: 0,
      myParam: myParam,
      modelClinic: {},
      paylink: "",
      myParamTel: myParamTel,
      paymentRequired: false,
      payment: false,
    };
  }

  genereteRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      auth,
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        },
      }
    );
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  searchScheduling = async () => {
    if (this.validate() == 0) {
      const { formFilter, myParam } = this.state;
      this.setState({ loading: true });
      await axios
        .get(`${URL_Scheduling}/GetByPhone`, {
          params: {
            cellPhoneOption: CharacterRemover.removeAll(
              formFilter.cellPhoneOption
            ),
            pageNumber: 1,
            pageSize: formFilter.pageSize,
            myParam: myParam,
          },
        })
        .then((resp) => {
          const { data } = resp;
          this.setState({ listSchedulings: data, loading: false });
        })
        .catch((er) => {
          this.setState({ loading: false });
        });
    }
  };

  componentDidMount = async () => {
    await this.consultClinic();
    await this.searchScheduling();
  };

  consultClinic = async () => {
    const { myParam } = this.state;
    if (myParam != "") {
      await axios
        .get(`${URL_PreScheduling}/GetClinicById/${myParam}`)
        .then((resp) => {
          const { data } = resp;
          if (data != "Clínica não localizada!")
            this.setState({
              modelClinic: data,
              paymentRequired: data.paymentRequired,
              payment: data.payment,
              loadingClinic: false,
            });
        });
    }
  };

  consultPayLink = async (id) => {
    const { myParam } = this.state;
    if (id != "") {
      await axios
        .get(`${URL_DigitalPayment}/getlink`, {
          params: {
            schedulingid: id,
            myParam: myParam,
          },
        })
        .then(async (resp) => {
          const { data } = resp;
          if (data.status == null) {
            window.open(data.paymentUrl, "_blank", "noopener,noreferrer");
          } else if (data.status == "created") {
            swal({
              title: "Aguardando o pagamento!",
              icon: "warning",
              buttons: ["Cancelar", "Ir para a tela"],
            }).then((e) => {
              if (e) {
                window.open(data.paymentUrl, "_blank", "noopener,noreferrer");
              }
            });
          } else if (data.status == "paid") {
            swal({
              title: "Pagamento foi efetuado com sucesso!!!",
              icon: "success",
            }).then((resp) => {
              if (resp) {
                this.searchScheduling();
              }
            });
          } else if (data.status == "expired") {
            swal({
              title: "Período expirado para pagamento!",
              icon: "warning",
            });
          }
        });
    }
  };

  setValues = (field, e) => {
    const { formFilter } = this.state;
    formFilter[field] = e.target.value;
    this.setState({ formFilter });
  };

  validate = () => {
    const { formFilter } = this.state;
    const errors = {};
    let isError = 0;
    let phone = CharacterRemover.removeAll(formFilter.cellPhoneOption);

    if (!phone || phone.length < 11) {
      isError++;
      errors.cellPhoneOptionError = true;
    } else errors.cellPhoneOptionError = false;

    this.setState({
      errors: errors,
    });
    return isError;
  };

  closeModal = () => {
    if (window.recaptchaVerifier && this.recaptchaWrapperRef) {
      window.recaptchaVerifier.clear();
      this.recaptchaWrapperRef.innerHTML = `<div id="recaptcha-container"></div>`;
    }
    this.toggle();
  };

  loadMore = () => {
    const { formFilter } = this.state;
    formFilter.pageSize += 10;
    this.setState({ formFilter });
    this.searchScheduling();
  };

  render() {
    const { listSchedulings, loading, modelClinic, myParam } = this.state;
    const { results } = listSchedulings;
    return (
      <div
        style={{
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {loading && <Spinner />}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "2px",
            alignSelf: "stretch",
            marginBottom: "24px",
          }}
        >
          <button
            style={{
              display: "flex",
              minHeight: "40px",
              maxHeight: "40px",
              width: "40px",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
              borderRadius: "4px",
              border: "1px",
              paddingTop: "9px",
              paddingRight: "16px",
              paddingBottom: "9px",
              paddingLeft: "16px",
              backgroundColor: "transparent",
            }}
            onClick={() => window.history.back()}
          >
            <FaChevronLeft
              style={{ color: "#171821", width: "14px", height: "14px" }}
            />
          </button>

          <div
            style={{
              color: "var(--Color-Primary-500, #171821)",
              fontFamily: "inter,sans-serif",
              fontSize: "18px",
              fontStyle: "normal",
              fontWeight: "600",
              lineHeight: "normal",
            }}
          >
            Agendamentos
          </div>
        </div>
        <div
          style={{
            display: "flex",
            paddingRight: "6px",
            paddingLeft: "6px",
            alignItems: "center",
            gap: "10px",
            alignSelf: "stretch",
            justifyContent: "center",
            paddingBottom: "24px",
          }}
        >
          <a
            style={{
              textAlign: "center",
              fontFamily: "inter,sans-serif",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "normal",
            }}
          >
            Toque no agendamento para visualizar mais detalhes, desmarcar seu
            horário ou efetuar o pagamento, se necessário.
          </a>
        </div>
        {!loading && results.length == 0 ? (
          <h6
            className="text-center"
            style={{
              fontFamily: "inter,sans-serif",
              color: "#FF5555",
            }}
          >
            Você ainda não realizou nenhum agendamento
          </h6>
        ) : null}
        {results.map((e, index) => (
          <CardBody
            e={e}
            myParam={myParam}
            modelClinic={modelClinic}
            index={index}
          />
        ))}
        <div className="text-center">
          <button
            style={{
              borderRadius: "4px",
              border: "1px solid var(--Color-Gray-100, #E3E3E3)",
              padding: "8px 12px",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
              alignSelf: "stretch",
              backgroundColor: "#FAFAFA",
              width: "226px",
            }}
            onClick={(e) => this.loadMore()}
          >
            {" "}
            Carregar mais
            <MdExpandMore style={{ color: "#171821" }} />
          </button>
        </div>
      </div>
    );
  }
}

function CardBody(props) {
  const navigate = useNavigate();
  const dataScheduling = props.e;
  const modelClinic = props.modelClinic;
  const handleClick = (event) => {
    event.preventDefault();
    navigate("/" + props.myParam + "/viewschedulingdata", {
      state: { dataScheduling, modelClinic },
    });
  };

  const toDefineStatus = (status) => {
    switch (status) {
      case 0:
        return "Agendado";
      case 2:
        return "Atendido";
      case 5:
        return "Particular";
      case 6:
        return "Chegou";
      case 7:
        return "Confirmado";
      case 1:
        return "Cancelado";
      case 3:
        return "Faltou";
      case 4:
        return "Desmarcou";
      default:
        break;
    }
  };
  const toDefineStatusColor = (status, type) => {
    switch (status) {
      case 0:
        return type == "color"
          ? "var(--Color-Blue-Pure, #0040FF)"
          : "var(--Color-Blue-25, #F6F9FF)";
      case 2:
        return type == "color"
          ? " var(--Color-Green-Pure, #008000)"
          : "var(--Color-Green-25, #F2FFF2)";
      case 5:
        return "Particular";
      case 6:
        return type == "color"
          ? "var(--Color-Green-Pure, #8fbc8f)"
          : "var(--Color-Green-25, #F2FFF2";
      case 7:
        return type == "color"
          ? "var(--Color-Purple-Pure, #8b008b)"
          : "var(--Color-Purple-25, #F6F9FF";
      case 1:
        return "Cancelado";
      case 3:
        return type == "color"
          ? "var(--Color-Red-Pure, #F00)"
          : "var(--Color-Red-25, #FFF8F8);";
      case 4:
        return type == "color"
          ? "var(--Color-Orange-Pure, #FFA500)"
          : "var(--Color-Orange-25, #FFF9EE)";
      default:
        break;
    }
  };
  return (
    <div
      key={props.index}
      onClick={handleClick}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "16px",
        alignSelf: "stretch",
      }}
      href=""
    >
      <div
        style={{
          display: "flex",
          paddingTop: "14px",
          paddingRight: "8px",
          paddingBottom: "14px",
          paddingLeft: "8px",
          justifyContent: "space-between",
          alignItems: "center",
          alignSelf: "stretch",
          borderRadius: "4px",
          border: "1px solid var(--Color-Gray-75, #EEE)",
          backgroundColor: "var(--Color-White-50, #FDFDFD)",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            gap: "16px",
            flex: "1 0 0",
            alignSelf: "stretch",
          }}
        >
          <p
            style={{
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: "1",
              alignSelf: "stretch",
              overflow: "hidden",
              color: "var(--Color-Primary-500, #171821)",
              textOverflow: "ellipsis",
              fontFamily: "inter,sans-serif",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "normal",
            }}
          >
            {props.e.name}
          </p>
          <div
            style={{
              display: "flex",
              paddingTop: "6px",
              paddingRight: "12px",
              paddingBottom: "6px",
              paddingLeft: "12px",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
              borderRadius: "4px",
              background: toDefineStatusColor(props.e.status, "back"),
            }}
          >
            <p
              style={{
                color: toDefineStatusColor(props.e.status, "color"),
                fontFamily: "inter,sans-serif",
                fontSize: "14px",
                fontWeight: "500",
                fontStyle: "normal",
                lineHeight: "normal",
                margin: "0px",
              }}
            >
              {toDefineStatus(props.e.status)}
            </p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-end",
            gap: "16px",
            color: "#171821",
          }}
        >
          <p
            style={{
              color: "var(--Color-Primary-500, #171821)",
              textAlign: "right",
              fontFamily: "inter,sans-serif",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "normal",
            }}
          >
            {format(new Date(props.e.date), "dd/MM/yyyy", {})} às{" "}
            {format(new Date(props.e.date), "HH:mm")}
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: "8px",
              paddingTop: "6px",
              paddingRight: "12px",
              paddingBottom: "6px",
              paddingLeft: "12px",
              borderRadius: "4px",
              backgroundColor: `${
                props.e.paymentConfirmed
                  ? "var(--Color-Green-25, #F2FFF2)"
                  : "var(--Color-Red-25, #FFF8F8)"
              }`,
            }}
          >
            <p
              style={{
                color: `${
                  props.e.paymentConfirmed
                    ? "var(--Color-Green-Pure, #008000)"
                    : "var(--Color-Red-Pure, #F00)"
                }`,
                fontFamily: "inter,sans-serif",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
                margin: "0px",
              }}
            >
              {props.e.paymentConfirmed ? "Pago" : "Aberto"}
            </p>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "1px", marginBottom: "1px" }} />
    </div>
  );
}
