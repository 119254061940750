import "bootstrap/dist/css/bootstrap.min.css";
import Router from "./Router";
import { BrowserRouter } from "react-router-dom";
import { Component } from "react";
import "./App.css";
import { history } from "./history";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

export default class App extends Component {
  onServiceWorkerUpdate = (registration) => {
    if (registration && registration.waiting) this.refreshAction(registration);
  };

  updateServiceWorker = (registration) => {
    const waitingWorker = registration && registration.waiting;
    if (waitingWorker) {
      waitingWorker.addEventListener("statechange", (event) => {
        if (event.target.state === "activated") {
          console.log("reloaded update SW");
          this.forceUpdate();
          // window.location.href = process.env.HOST;
          window.location.reload();
        }
      });
      waitingWorker.postMessage({ type: "SKIP_WAITING" });
    }
  };

  componentDidMount = async () => {
    if (
      process.env.NODE_ENV === "production" ||
      process.env.NODE_ENV === "staging"
    ) {
      serviceWorkerRegistration.register({
        onUpdate: this.onServiceWorkerUpdate,
      });
    }

    // Listen for route changes and trigger update check
    history.listen(() => {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.getRegistration().then((registration) => {
          if (registration) {
            registration.update();
          }
        });
      }
    });
  };

  refreshAction = (registration) => {
    localStorage.clear();
    sessionStorage.clear();
    this.updateServiceWorker(registration);
    console.log("teste refreshAction teste");

    // Redirect to /login after confirming the update
    // Redirect to the full login URL
    // setTimeout(() => {
    //   window.location.href = `${window.location.origin}/login`;
    //   // navigate("/login");
    // }, 300); // Small delay to let the service worker update finish
  };

  render() {
    return (
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    );
  }
}
